// const cf_about_the_book = `
// <div style="padding:10px;text-align:left;float:left;" >
//     <img id="iqmn4" data-gjs-type="image" draggable="true" style="float:right;" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" class=""/>
//     <b id="il2wk" data-gjs-type="text" draggable="true" class="">
//         About the Book
//     </b>
//     <br id="ip7tb" data-gjs-type="default" draggable="true">
//     <i id="itond" data-gjs-type="text" draggable="true">
//         <b id="iwu3i" data-gjs-type="text" draggable="true" class="">“Lorem ipsum dolor sit amet...”
//         <br id="i3otr" data-gjs-type="default" draggable="true"></b>
//     </i>
//     <span  id="iwu56" data-gjs-type="text" draggable="true" class="">
//         <br id="i4xq1" data-gjs-type="default" draggable="true">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//         <br id="iy52y" data-gjs-type="default" draggable="true">
//         <br id="iodal" data-gjs-type="default" draggable="true">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
//         <br id="iq06p" data-gjs-type="default" draggable="true"><br id="i1tol" data-gjs-type="default" draggable="true">
//         Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
//     </span>
// </div>`

const about_the_book = `
<table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" style="max-width:640px">
    <tbody>
        <tr>
            <td align="left" style="padding:15px 10px 10px 10px">
                <table width="50%" align="left" style="max-width: 391px; width:50%; border-collapse:collapse" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="text-align:center; padding-right: 20px;">
                                <img style="display:block;max-width:391px;margin:0px" border="0" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" width="235" alt="" align="left" class="CToWUd about-the-book-img" data-bit="iit"> 	                    
                            </td>
                           <!-- <td height="361" width="15"></td>-->
                        </tr>
                        <!--<tr>
                            <td height="10" colspan="2"></td>
                        </tr> -->
                    </tbody>
                </table>

                <p style="margin:0px 0px 10px 0px;line-height:27px"><strong>Blurb:</strong></p>
                <p style="margin:0px 0px 10px 0px;line-height:27px">Lorem ipsum dolor sit amet . The graphic and typographic operators know this well, in reality all the professions dealing with the universe of communication have a stable relationship with these words, but what is it? Lorem ipsum is a dummy text without any sense.</p>
                <p style="margin:0px 0px 10px 0px;line-height:27px">It is a sequence of Latin words that, as they are positioned, do not form sentences with a complete sense, but give life to a test text useful to fill spaces that will subsequently be occupied from ad hoc texts composed by communication professionals.
                  It is certainly the most famous placeholder text even if there are different versions distinguishable from the order in which the Latin words are repeated.</p>
                <p style="margin:0px 0px 10px 0px;line-height:27px">Lorem ipsum contains the typefaces more in use, an aspect that allows you to have an overview of the rendering of the text in terms of font choice and font size .</p>
            </td>
        </tr>
    </tbody>
</table>
`
const about_the_book2 = `
<table width="100%" cellspacing="0" cellpadding="0" border="0" align="center" style="max-width:640px">
    <tbody>
        <tr>
            <td align="left" style="padding:15px 10px 10px 10px">
                <table width="50%" align="left" style="min-width:370px; max-width: 391px; width:50%; border-collapse:collapse" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                        <tr>
                            <td style="text-align:center; padding-right: 20px;">
                                <img style="display:block; height: auto; min-width: 100%; max-width:391px;margin:0px" border="0" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik0yLjI4IDNMMSA0LjI3bDIgMlYxOWMwIDEuMS45IDIgMiAyaDEyLjczbDIgMkwyMSAyMS43MiAyLjI4IDNtMi41NSAwTDIxIDE5LjE3VjVhMiAyIDAgMCAwLTItMkg0LjgzTTguNSAxMy41bDIuNSAzIDEtMS4yNUwxNC43MyAxOEg1bDMuNS00LjV6Ij48L3BhdGg+CiAgICAgIDwvc3ZnPg==" width="235" alt="" align="left" class="CToWUd about-the-book-img" data-bit="iit"> 	                    
                            </td>
                           <!-- <td height="361" width="15"></td>-->
                        </tr>
                        <!--<tr>
                            <td height="10" colspan="2"></td>
                        </tr> -->
                    </tbody>
                </table>

                <p style="margin:0px 0px 10px 0px;line-height:27px"><strong>Blurb:</strong></p>
                <p style="margin:0px 0px 10px 0px;line-height:27px">Lorem ipsum dolor sit amet . The graphic and typographic operators know this well, in reality all the professions dealing with the universe of communication have a stable relationship with these words, but what is it? Lorem ipsum is a dummy text without any sense.</p>
                <p style="margin:0px 0px 10px 0px;line-height:27px">It is a sequence of Latin words that, as they are positioned, do not form sentences with a complete sense, but give life to a test text useful to fill spaces that will subsequently be occupied from ad hoc texts composed by communication professionals.
                  It is certainly the most famous placeholder text even if there are different versions distinguishable from the order in which the Latin words are repeated.</p>
                <p style="margin:0px 0px 10px 0px;line-height:27px">Lorem ipsum contains the typefaces more in use, an aspect that allows you to have an overview of the rendering of the text in terms of font choice and font size .</p>
            </td>
        </tr>
    </tbody>
</table>
`

export default about_the_book2
