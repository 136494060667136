import React, { useState, useEffect, useContext, useRef } from 'react'
import { ApiService, BASE_URL } from '../services/ApiService'
import User from '../services/User'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Select from 'react-select'
import LoadingContext from '../ToggleContext'
import { Form, Tabs, Tab } from 'react-bootstrap'
import NodeItem from './NodeItem'

const mSwal = withReactContent(Swal)

const FlowEditor = () => {
	const { autId } = useParams()
	const loadingContext = useContext(LoadingContext)
	const automationContainerRef = useRef(null)

	const [tab, setTab] = useState('actions')
	const [data, setData] = useState({})
	const [groups, setGroups] = useState([])
	const [templates, setTemplates] = useState([])
	const [hasTrigger, setHasTrigger] = useState(false)
	const [avlCampaigns, setAvlCampaigns] = useState([])
	const [cmpLinks, setCmpLinks] = useState([])
	const [accountId, setAccountId] = useState('')
	const [workflowEmails, setWorkflowEmails] = useState([])

	const [excludeNodes, setExcludeNodes] = useState([])
	const [nodes, setNodes] = useState([
		{ id: 1, type: 'trigger', output: [] },
		// { id: 2, type: 'email', input: [{ id: 1 }], output: [{ id: 3 }] },
		// { id: 3, type: 'condition', input: [{ id: 2 }], output: [{ id: 4 }, { id: 5 }] },
		// { id: 4, type: 'action', input: [{ id: 3 }], output: [{ id: 6 }] },
		// { id: 5, type: 'action', input: [{ id: 3 }], output: [{ id: 7 }] },
		// { id: 6, type: 'action', input: [{ id: 4 }], output: [] },
		// { id: 7, type: 'delay', input: [{ id: 6 }], output: [{ id: 8 }] },
		// { id: 8, type: 'email', input: [{ id: 7 }], output: [] },
		// { id: 8, type: 'action', input: [{ id: 7 }], output: [] },
	])

	//State for Sidebar Setting
	const [selectedNode, setSelectedNode] = useState(null)

	useEffect(() => {
		const init = async () => {
			if (autId) loadData(autId)
			let user = User.get()
			let account = await ApiService.get(`fairymailer/getAccount`, user.jwt)
			setAccountId(account.data.user.account.id)
			loadGroups(account.data.user.account.id)
			loadCampaigns(account.data.user.account.id)
			loadTemplates(account.data.user.account.id)
			loadCmpLinks()
		}
		init()
	}, [autId])
	loadingContext.setLoading_(false)

	const goToTab = (selectedTab) => setTab(selectedTab)

	const loadData = async (autId) => {
		let user = User.get()
		// let resp = await ApiService.get(`automations?filters[uuid]=${autId}&populate=*`, user.jwt) //old
		let resp = await ApiService.get(`fairymailer/getAutomations?filters[uuid]=${autId}&populate=*`, user.jwt)
		const loadedNodes = resp.data.data[0].design

		if (loadedNodes && loadedNodes.length > 0) {
			setNodes(loadedNodes)
		}

		console.log('resp from automation is : ')
		setData(resp.data.data[0])
	}

	const loadGroups = async (accountid = 0) => {
		let user = User.get()
		let resp = await ApiService.get(`groups?polulate=*&filters[account]=${accountid}&pagination[pageSize]=100`, user.jwt)
		setGroups(resp.data.data.map((v) => ({ value: v.id, label: v.attributes.name })))
	}

	const loadCampaigns = async (accountid = 0) => {
		let user = User.get()
		let account = await ApiService.get(`fairymailer/getAccount`, user.jwt)
		account = account.data.user.account
		let resp = await ApiService.get(`campaigns?filters[account]=${account.id}&pagination[pageSize]=100&pagination[page]=1&sort[createdAt]=desc`, user.jwt)
		setAvlCampaigns(resp.data.data.map((v) => ({ value: v.id, label: v.attributes.name })))
	}

	const loadTemplates = async (accountid = 0) => {
		let user = User.get()
		let resp = await ApiService.get(`templates?polulate=*&filters[account]=${accountid}`, user.jwt)
		// setTemplates(resp.data.data.map((v) => ({ value: v.id, label: v.attributes.name })))
		setTemplates(resp.data.data)
		console.log('templates are : ', resp.data.data)
	}

	const loadCmpLinks = async () => {
		let user = User.get()
		let account = await ApiService.get(`fairymailer/getAccount`, user.jwt)
		account = account.data.user.account
		let campaigns = (await ApiService.get(`campaigns?filters[account]=${account.id}&pagination[pageSize]=100&pagination[page]=1&sort[createdAt]=desc`, user.jwt)).data.data

		if (campaigns) {
			let links = []
			campaigns.forEach((cmp) => {
				if (cmp.attributes && cmp.attributes && cmp.attributes.design) {
					let dsgn = JSON.parse(cmp.attributes.design)
					let cmplinks = extractLinksFromCampaignDesign(dsgn.components)
					// if (cmplinks.length > 0) links = [...cmplinks]
					if (cmplinks.length > 0)
						cmplinks.forEach((ll) => {
							if (!links.includes(ll)) links.push(ll)
						})
				}
			})

			const finalLinks = links
				.map((link) => {
					return {
						label: link,
						value: link,
					}
				})
				.filter((item) => item.label !== null && item.label !== undefined && item.label !== '')

			setCmpLinks(finalLinks)
		}
	}

	const extractLinksFromCampaignDesign = (components = [], links = []) => {
		components.forEach((component) => {
			if (component.components && component.components.length > 0) {
				links = [...extractLinksFromCampaignDesign(component.components, links)]
			}
			if (component.type === 'link') {
				links.push(component?.attributes?.href)
			}
		})
		return links
	}

	const shortenString = (str) => {
		const maxLength = 50
		return str.length <= maxLength ? str : `${str.substring(0, Math.floor((maxLength - 3) / 2))} ... ${str.substring(str.length - Math.floor((maxLength - 3) / 2))}`
	}

	const exportData = () => {
		console.log('validate nodes are : ', validateNodes())

		const isAutomationValid = validateNodes()
		console.log('isAutomationValid', isAutomationValid)

		if (!isAutomationValid) {
			mSwal.fire({ icon: 'error', text: 'You have empty actions in your flow. You can delete unwanted actions.' })
			return
		}

		mSwal.fire({
			icon: 'info',
			text: 'Please wait...',
			didOpen: async () => {
				Swal.showLoading()
				let user = await User.get()
				let newData = { id: data.id, data: { design: nodes, active: data.active } }
				console.log('new data are : ', newData)

				let resp
				if (data && data.id > 0) {
					// resp = await ApiService.put(`automations/${data.id}`, newData, user.jwt)
					resp = await ApiService.post(`fairymailer/save-automation/`, newData, user.jwt)
				} else {
					alert('error')
				}
				if (resp?.data?.data?.id) {
					mSwal.fire({ icon: 'success', timer: 500 })
				} else {
					mSwal.fire({ icon: 'error', text: `Failed to save your changes. If this problem persists, contact our support team.` })
				}
			},
		})

		// let dataExport = this.drawflow.export()
		// let hasTrigger = false
		// Object.keys(dataExport.drawflow.Home.data).forEach((key) => {
		// 	if (dataExport.drawflow.Home.data[key].name.includes('when-')) {
		// 		hasTrigger = true
		// 	}
		// })
		// if (!hasTrigger) {
		// 	mSwal.fire({ icon: 'error', text: 'You need to add a trigger event first.' })
		// 	return
		// }
		// let invalid = false
		// Object.keys(dataExport.drawflow.Home.data).forEach((key) => {
		// 	const nodeData = dataExport.drawflow.Home.data[key].data
		// 	if (nodeData.group === 'none' || nodeData.template === 'none') invalid = true
		// })
		// if (invalid) {
		// 	mSwal.fire({ icon: 'error', text: 'You have empty actions in your flow. You can delete unwanted actions by right-clicking them.' })
		// 	return
		// }
		// mSwal.fire({
		// 	icon: 'info',
		// 	text: 'Please wait...',
		// 	didOpen: async () => {
		// 		Swal.showLoading()
		// 		let user = await User.get()
		// 		let newData = { data: { design: dataExport, active: data.attributes.active } }
		// 		let resp
		// 		if (data && data.id > 0) {
		// 			resp = await ApiService.put(`automations/${data.id}`, newData, user.jwt)
		// 		} else {
		// 			alert('error')
		// 		}
		// 		if (resp?.data?.data?.id) {
		// 			mSwal.fire({ icon: 'success', timer: 500 })
		// 		} else {
		// 			mSwal.fire({ icon: 'error', text: `Failed to save your changes. If this problem persists, contact our support team.` })
		// 		}
		// 	},
		// })
	}

	const addNode = (type, input = 0, position = 0) => {
		let maxid = 0
		nodes.map((n) => {
			if (n && n.id && n.id > maxid) maxid = n.id
		})
		const newNode = { id: maxid + 1, type, input: [{ id: input }], output: [] }
		let tmp = nodes
		tmp.forEach((n, i) => {
			if (n.id == input) {
				tmp[i].output[position] = { id: newNode.id }
			}
		})
		tmp.push(newNode)
		setNodes(tmp)
		refreshNodes()
		setTimeout(() => {
			setExcludeNodes([...excludeNodes])
			setSelectedNode(nodes[nodes.length - 1])
		}, 100) //refresh state, re-render
	}

	const selectNode = (event) => {
		const nodeId = event.target.closest('li').dataset.nodeid
		event.preventDefault()
		setSelectedNode(
			nodes.find((node) => {
				return node.id == nodeId
			})
		)
	}

	const triggerOptions = [
		{ value: 'when-user-subscribes', label: 'When Subscriber Joins a Group' },
		{ value: 'when-user-opens-campaign', label: 'When Subscriber Opens a Campaign' },
		{ value: 'when-user-clicks-link', label: 'When Subscriber Clicks a Link' },
	]

	const conditionOptions = [
		{ value: 'when-user-opens-campaign', label: 'If Subscriber has opened a campaign' },
		{ value: 'when-user-clicks-link', label: 'If Subscriber has clicked a link' },
	]

	const workflowConditionOptions = [
		{ value: 'cmp_open', label: 'was opened' },
		{ value: 'cmp_not_open', label: 'was not opened' },
		{ value: 'cmp_link_clicked', label: 'had a specific link clicked' },
		{ value: 'cmp_link_not_clicked', label: 'had a specific link not clicked' },
	]

	const getTplIdLinks = (nodeId) => {
		let links = []
		const tplId = nodes.filter((node) => node.id === nodeId)[0]?.data?.tplId

		console.log('node inside getTplLinks are : ', nodeId)

		if (templates.length > 0 && tplId) {
			const tplDesign = JSON.parse(templates.filter((template) => template.id === tplId)[0]?.attributes?.design)
			const templateLinks = extractLinksFromCampaignDesign(tplDesign.components)

			console.log('templateLinks', templateLinks)

			if (templateLinks.length > 0) {
				templateLinks.forEach((ll) => {
					if (!links.includes(ll)) links.push(ll)
				})
			}

			links = links.map((link) => ({
				value: link,
				label: link,
			}))
		}

		return links
	}

	const workflowEmailLinkOptions = getTplIdLinks()

	const conditionOptions2 = [
		{ value: 'workflow-activity', label: 'Workflow Activity' },
		{ value: 'cmp-activity', label: 'Campaign Activity' },
	]

	const actionOptions = [
		{ value: 'copy-to-group', label: 'Copy to Group' },
		{ value: 'move-to-group', label: 'Move to Group' },
		{ value: 'remove-from-group', label: 'Remove from Group' },
		{ value: 'unsubscribe', label: 'Unsubscribe' },
	]

	const templateOptions = templates.map((v) => ({ value: v.attributes.uuid, label: v.attributes.name }))
	// const workflowCampaigns = workflowEmails.map(()=>({value : v.attributes.uuid, label: v.attributes.name}))
	// const workflowCampaigns = [
	// 	{ value: '123', label: 'This is a test email from workflow' },
	// 	{ value: '1234', label: 'This is a test email from workflow 2' },
	// ]

	const workflowCampaigns =
		nodes.filter((node) => node.type === 'email').length > 0
			? nodes
					.filter((node) => node.type === 'email')
					.map((node) => {
						if (node.data) return { value: node.id, label: node.data.emailSubject }
					})
			: []

	const handleTriggerSelectChange = (ev) => {
		// Update the `name` and reset additional settings in `selectedNode`

		if (selectedNode.type === 'delay') {
			ev.value = 'delay'
		}

		setSelectedNode((prevNode) => ({
			...prevNode,
			name: ev.value, // Assign selected trigger option to name
			// data: { ...prevNode.data, group: '' }, // Reset group data if the trigger changes
		}))

		if (selectedNode.type === 'email') {
			const tplId = templates.find((item) => {
				return item.attributes.uuid === ev.value
			}).id

			console.log('tplId is ', tplId)

			setSelectedNode((prevNode) => ({
				...prevNode,
				templateName: ev.label,
				data: { ...prevNode.data, tplId: tplId },
			}))
		}
	}

	const handleDelayData = (ev) => {
		if (!ev) return
		console.log('handleDelayData event', ev)
		// `selectedOptions` is an array of selected values for isMulti

		if (ev.target.name === 'delayNumber') console.log(ev.target.value)

		setSelectedNode((prevNode) => ({
			...prevNode,
			name: 'delay',
			data: { ...prevNode.data, delayValue: ev.target.value }, // Update data.group with an array of selected groups
		}))
	}

	const handleEmailSubjectData = (ev) => {
		// `selectedOptions` is an array of selected values for isMulti

		console.log(ev.target.name)

		// return

		if (ev.target.name === 'emailSubject') {
			console.log('asdas', ev.target.value)
		}

		setSelectedNode((prevNode) => ({
			...prevNode,
			// name: 'emailSubject',
			data: { ...prevNode.data, emailSubject: ev.target.value }, // Update data.group with an array of selected groups
		}))
	}

	const handleWorkflowCondition = (ev) => {
		if (!ev) return
		console.log('workflowCondtion is : ', ev.value)

		setSelectedNode((prevNode) => ({
			...prevNode,
			data: { ...prevNode.data, trigger: ev.value },
		}))
	}

	const handleWorkflowEmailLink = (ev) => {
		if (!ev) return
		console.log('workflowLink is : ', ev.value)

		setSelectedNode((prevNode) => ({
			...prevNode,
			data: { ...prevNode.data, link: ev.value },
		}))
	}

	const handleCmpEmailLink = (ev) => {
		if (!ev) return
		console.log('cmpLink is : ', ev.value)

		setSelectedNode((prevNode) => ({
			...prevNode,
			data: { ...prevNode.data, link: ev.value },
		}))
	}

	const handleAdditionalChange = (selectedOptions) => {
		// Check if selectedOptions is an array (isMulti) or a single object
		if (!Array.isArray(selectedOptions)) {
			selectedOptions = [selectedOptions]
		}

		let selectedValue = selectedOptions.length > 0 ? selectedOptions.map((option) => option?.value) : []
		let selectedLabel = selectedOptions.length > 0 ? selectedOptions.map((option) => option?.label) : []
		let triggerType
		if (!selectedNode.name) selectedNode.name = selectedNode.type
		switch (selectedNode.name) {
			case 'when-user-subscribes':
				triggerType = 'group'
				break
			case 'when-user-opens-campaign':
				triggerType = 'cmp'
				break
			case 'when-user-clicks-link':
				triggerType = 'link'
				break
			case 'delay':
				triggerType = 'delay'
				selectedValue = selectedValue[0]
				handleDelayData()
				break
			case 'copy-to-group':
				triggerType = 'group'
				break
			case 'move-to-group':
				triggerType = 'group'
				break
			case 'remove-to-group':
				triggerType = 'group'
				break
			case 'workflow-activity':
				console.log('selectedValue inside workflow activity is : ', selectedValue)
				triggerType = 'email_node_id'
				selectedValue = selectedValue[0]
				break
			case 'cmp-activity':
				console.log('selectedValue inside workflow activity is : ', selectedValue)
				triggerType = 'cmp'
				selectedValue = selectedValue[0]
				break
			default:
				break
		}
		console.log(triggerType, selectedValue)
		setSelectedNode((prevNode) => ({
			...prevNode,
			data: { [triggerType]: selectedValue },
			meta: { label: selectedLabel && selectedLabel[0] ? selectedLabel[0] : '' },
		}))
	}
	const handleApply = async () => {
		if (selectedNode) {
			if (selectedNode.type === 'email') {
				// const response = await ApiService.post(
				// 	'fairymailer/save-automation-campaign/',
				// 	{
				// 		template_id: selectedNode?.data?.tplId,
				// 		subject: selectedNode?.data?.emailSubject,
				// 		automation_udid: autId,
				// 	},
				// 	User.get().jwt
				// )

				const getResponse = await ApiService.post(
					'fairymailer/get-automation-campaigns',
					{
						automation_udid: autId,
					},
					User.get().jwt
				)
				console.log('get workflow cmp[s ', getResponse)

				// console.log('response from save node workflow campagin ', response)
				//Here will be the Custom POST request.
				// data that will be sent are
				// templateUdId
				// automationUdid
				// subject
			}

			const updatedNodes = nodes.map((node) => (node.id === selectedNode.id ? { ...selectedNode } : node))
			setNodes(updatedNodes)
			setSelectedNode(null)
			console.log('Updated nodes:', updatedNodes)
		}
	}

	const removeNode = (node) => {
		Swal.fire({ icon: 'question', text: 'Are you sure you want to remove this node?', showDenyButton: true, focusDeny: true }).then((res) => {
			if (res.isConfirmed) {
				// recursRemove(node, nodes)
				// recursRemoveCK(node, nodes)
				getNodesToRemove(node, nodes)
			}
		})
	}
	const recursRemove = (nodeId, initNodes) => {
		console.log('node id is ', nodeId)

		initNodes = initNodes.filter((n) => n.id != nodeId)
		console.log('initNodes are inside remove', initNodes)
		setNodes(initNodes)

		initNodes = initNodes.filter((n) => !n.input || n.input.length < 1 || (n.input && n.input[0] && n.input[0].id != nodeId))
		console.log('initNodes are inside remove', initNodes)

		for (let i = 0; i < initNodes.length; i++) {
			if (initNodes[i] && initNodes[i].output && initNodes[i].output.length > 0) {
				// initNodes[i].output = initNodes[i].output.filter((o) => o.id != nodeId)
				if (initNodes[i].output[0] && initNodes[i].output[0].id && initNodes[i].output[0].id == nodeId) initNodes[i].output[0] = {}
				if (initNodes[i].output[1] && initNodes[i].output[1].id && initNodes[i].output[1].id == nodeId) initNodes[i].output[0] = initNodes[i].output = [initNodes[i].output[0]]
			}
		}

		console.log('initNodes are 2: ', initNodes)

		setNodes(initNodes)
		refreshNodes()
	}

	function recursRemoveCK(nodeIdToRemove, nodes) {
		// console.log('node to remove : ', nodeIdToRemove)
		// console.log('nodes ', nodes)

		let nodeToRemove = nodes.find((node) => node.id === nodeIdToRemove)

		let finalNodes = nodes
		if (nodeToRemove.type !== 'condition') {
			let nodeHasOutput = false
			let output = -1

			do {
				if (Array.isArray(nodeToRemove.output) && nodeToRemove.output.length !== 0) {
					output = nodeToRemove.output[0].id
					finalNodes = finalNodes.filter((node) => node.id !== nodeToRemove.id)
					nodeToRemove = finalNodes.find((node) => node.id === output)
					nodeHasOutput = true
				} else {
					finalNodes = finalNodes.filter((node) => node.id !== nodeToRemove.id)
					nodeHasOutput = false
				}
				console.log('finalNodes : ', finalNodes)
				console.log('output : ', output)
				console.log('nodeToRemove : ', nodeToRemove)
			} while (nodeHasOutput)
		} else {
			console.log('nodeToRemove', nodeToRemove)
			let nodesToBeRemoved = [nodeIdToRemove]

			for (let i = 0; i < 2; i++) {
				console.log('i is ', i)
				let tempNodeToBeRemoved = nodeToRemove

				while (tempNodeToBeRemoved.output.length !== 0) {
					let output = tempNodeToBeRemoved.output.id
					nodesToBeRemoved.push(output)
					console.log('output is ', output)
					tempNodeToBeRemoved = nodes.find((node) => node.id === output)
					console.log('asda2', tempNodeToBeRemoved)
					console.log('nodeToRemove.output.length !== 0', tempNodeToBeRemoved.output.length !== 0)
				}
			}

			console.log('nodesToBeRemoved : ', nodesToBeRemoved)
		}

		setNodes(finalNodes)
		refreshNodes()
	}

	function getNodesToRemove(nodeId, nodes) {
		const nodesToBeRemoved = []
		function traverse(nodeId) {
			const node = nodes.find((n) => n.id === nodeId)
			if (node) {
				nodesToBeRemoved.push(node.id)
				for (let output of node.output) {
					if (output && output.id) traverse(output.id)
				}
			}
		}
		traverse(nodeId)

		console.log(nodesToBeRemoved)
		console.log(
			'final nodes are : ',
			nodes.filter((node) => {
				return !nodesToBeRemoved.includes(node.id)
			})
		)

		const finalNodes = nodes.filter((node) => {
			return !nodesToBeRemoved.includes(node.id)
		})
		for (let i = 0; i < finalNodes.length; i++) {
			if (finalNodes[i] && finalNodes[i].output && finalNodes[i].output.length > 0) {
				finalNodes[i].output = finalNodes[i].output.map((ni) => {
					return ni && ni.id && !nodesToBeRemoved.includes(ni.id) ? ni : {}
				})
				if (!finalNodes[i].output[1] && (!finalNodes[i].output[0] || !finalNodes[i].output[0].id)) finalNodes[i].output = []
			}
		}
		setNodes(finalNodes)
		setSelectedNode(null)
		refreshNodes()
		// return nodesToBeRemoved
	}

	function recursivelyRemoe(nodeIdToRemove, nodes) {
		const nodesToRemove = new Set()

		function addNodeAndDescendants(nodeId) {
			nodesToRemove.add(nodeId)
			const node = nodes.find((n) => n.id === nodeId)

			if (node && node.output && node.output.length > 0) {
				node.output.forEach((outputNode) => {
					addNodeAndDescendants(outputNode.id)
				})
			}
		}

		addNodeAndDescendants(nodeIdToRemove)
		const initNodes = nodes.filter((node) => !nodesToRemove.has(node.id))
		setNodes(initNodes)
		refreshNodes()
	}

	const getChildrenOfCondition = (nodes, nodeId, conditionIndex) => {
		const node = nodes.find((n) => n && n.id && n.id == nodeId)
		if (!node || !node.output) {
			return []
		}
		let nextNodeId
		if (node.type == 'condition') {
			nextNodeId = node.output[conditionIndex] ? node.output[conditionIndex].id : false
		} else {
			nextNodeId = node.output[conditionIndex] ? node.output[conditionIndex].id : node.output[conditionIndex - 1] ? node.output[conditionIndex - 1].id : false
		}
		if (!nextNodeId) {
			return []
		}
		let children = getChildrenOfCondition(nodes, nextNodeId, conditionIndex)
		let nextNode = nodes.filter((n) => n && n.id && n.id == nextNodeId)
		let nextChildren = []
		if (nextNode[0] && nextNode[0].output && nextNode[0].output.length > 0) {
			if (nextNode[0].output[0]) nextChildren = [...nextChildren, ...getChildrenOfCondition(nodes, nextNode[0].output[0].id, conditionIndex)]
			if (nextNode[0].output[1]) nextChildren = [...nextChildren, ...getChildrenOfCondition(nodes, nextNode[0].output[1].id, conditionIndex)]
		}
		let childrenof = [nextNode[0], ...children]
		let keymap = {}
		nodes.forEach((c) => {
			if (c && c.id) keymap[c.id] = c
		})
		for (let c = 0; c < childrenof.length; c++) {
			if (childrenof[c] && childrenof[c].input && childrenof[c].input[0] && childrenof[c].input[0].id) {
				childrenof[c].input[0] = keymap[childrenof[c].input[0].id]
			}
		}
		return childrenof
	}
	const refreshNodes = () => {
		let tmp = excludeNodes
		nodes.forEach((n) => {
			if (n.type == 'condition') {
				n.output.forEach((o) => {
					if (o && o.id) {
						if (!excludeNodes.includes(o.id)) tmp.push(o.id)
						let children0 = getChildrenOfCondition(nodes, o.id, 0).map((t) => t && t.id)
						let children1 = getChildrenOfCondition(nodes, o.id, 1).map((t) => t && t.id)
						children0.forEach((c) => {
							if (!excludeNodes.includes(c)) tmp.push(c)
						})
						children1.forEach((c) => {
							if (!excludeNodes.includes(c)) tmp.push(c)
						})
					} else {
						console.log('No o', o)
					}
				})
			}
		})
		setExcludeNodes(tmp)
		// console.log(nodes, excludeNodes)
	}

	useEffect(() => {
		console.log('Selected Node is : ', selectedNode)
		console.log('Nodes are : ', nodes)
	}, [selectedNode])

	useEffect(() => {
		refreshNodes()
	}, [nodes])
	useEffect(() => {
		const container = automationContainerRef.current
		if (container) {
			const centerPosition = container.clientWidth
			container.scrollLeft = centerPosition
		}
	}, [])

	const validationKeys = {
		type: ['id', 'type', 'name', 'data', 'output'],
		trigger: ['id', 'type', 'name', 'data', 'output', 'group'],
		email: ['id', 'type', 'name', 'data', 'output', 'emailSubject', 'tplId'],
		condition: ['id', 'type', 'name', 'data', 'output', 'cmp'],
		action: ['id', 'type', 'name', 'data', 'output', 'group'],
		delay: ['id', 'type', 'name', 'data', 'output', 'delayCount', 'delay'],
	}

	const validateNode = (node) => {
		let result = true
		switch (node.type) {
			case 'trigger':
				if (!node.name || !node.name.length > 0) {
					result = false
				} else {
					switch (node.name) {
						case 'when-user-subscribes':
							if (!node.data.group[0] || !node.data.group.length > 0) {
								result = false
							}
							break
						case 'when-user-opens-campaign':
							if (!node.data.cmp[0] || !node.data.cmp.length > 0) {
								result = false
							}
							break
						case 'when-user-clicks-link':
							if (!node.data.link[0] || !node.data.link.length > 0) {
								result = false
							}
							break
						default:
							break
					}
				}
				break
			case 'action':
				if (!node.name || !node.name.length > 0) {
					result = false
				} else {
					switch (node.name) {
						case 'copy-to-group':
							if (!node.data.group[0] || !node.data.group.length > 0) {
								result = false
							}
							break
						case 'move-to-group':
							if (!node.data.group[0] || !node.data.group.length > 0) {
								result = false
							}
							break
						case 'remove-from-group':
							if (!node.data.group[0] || !node.data.group.length > 0) {
								result = false
							}
							break
						default:
							break
					}
				}
				break
			case 'email':
				if (!node.name || !node.name.length > 0) {
					result = false
				} else {
					if (!node.data.emailSubject || !node.data.emailSubject.length > 0) {
						result = false
					}
					if (!node.data.tplId || !isNaN(node.data.tplId[0])) {
						result = false
					}
				}
				break
			case 'delay':
				if (!node.name || !node.name.length > 0) {
					result = false
				} else {
					if (!node.data.delay || !node.data.delay.length > 0) {
						result = false
					}
					if (!node.data.delayValue || !node.data.delayValue.length > 0) {
						result = false
					}
				}
				break
			case 'condition':
				if (!node.name || !node.name.length > 0) {
					result = false
				} else {
					switch (node.name) {
						case 'workflow-activity':
							if (!node.data.email_node_id || !(typeof node.data.email_node_id === 'number')) {
								result = false
							}

							if (!node.data.trigger || !node.data.trigger.length > 0) {
								result = false
							} else {
								if (node.data.trigger === 'cmp_link_clicked' || node.data.trigger === 'cmp_link_not_clicked') {
									if (!node.data.link || !node.data.link.length > 0) result = false
								}
							}

							// if (!node.data.cmp[0] || !node.data.cmp.length > 0) {
							// 	result = false
							// }
							break
						case 'cmp-activity':
							if (!node.data.cmp || !node.data.cmp.length > 0) {
								result = false
							}

							if (!node.data.trigger || !node.data.trigger.length > 0) {
								result = false
							} else {
								if (node.data.trigger === 'cmp_link_clicked' || node.data.trigger === 'cmp_link_not_clicked') {
									if (!node.data.link || !node.data.link.length > 0) result = false
								}
							}

							// if (!node.data.link[0] || !node.data.link.length > 0) {
							// 	result = false
							// }
							break
						default:
							break
					}
				}
				break
			default:
				break
		}

		return result
	}

	const validateNodes = () => {
		let result = true
		nodes.forEach((node) => {
			console.log('node is : ', node)
			console.log('validateNode is : ', validateNode(node))
			if (!validateNode(node)) result = false
		})
		console.log('result is ', result)
		return result
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css" />
			<div
				id="automation-builder"
				style={{
					overflow: 'scroll',
					cursor: 'grab',
					background: 'white',
					flex: '1 1 0%',
					height: 'calc(100vh - 70px)',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'start',
					maxWidth: 'calc(100vw - 20rem - 244px)',
				}}
				ref={automationContainerRef}
			>
				<ul style={{ listStyleType: 'none', color: 'black', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWidth: '200vw' }}>
					{nodes.map((node, idx) => {
						let children
						if (node.type == 'condition') {
							children = [getChildrenOfCondition(nodes, node.id, 0), getChildrenOfCondition(nodes, node.id, 1)]
						} else {
							children = []
						}
						if (!excludeNodes.includes(node.id))
							return (
								<NodeItem
									onSelect={(e) => selectNode(e)}
									key={node.id}
									node={node}
									type={node.type}
									onAdd={addNode}
									removeNode={removeNode}
									nodes={nodes}
									children={children}
									getChildrenOfCondition={getChildrenOfCondition}
								/>
							)
					})}
				</ul>
			</div>
			<div className="flow-sidebar" style={{ width: '20rem', display: 'flex', flexDirection: 'column', height: 'calc(100vh - 70px)' }}>
				{!hasTrigger ? (
					<>
						<h3>Settings</h3>

						{!selectedNode && (
							<div>
								<p className="form-control">Please select a node to edit its settings</p>
							</div>
						)}
						{selectedNode && selectedNode.type === 'trigger' && (
							<div style={{ flexGrow: '1' }}>
								<Select
									onChange={handleTriggerSelectChange}
									value={
										selectedNode.name
											? {
													value: selectedNode.name,
													label: triggerOptions.find((option) => {
														return option.value === selectedNode.name
													}).label,
											  }
											: null
									}
									options={triggerOptions}
									styles={{
										option: (provided) => ({
											...provided,
											color: 'black',
										}),
										control: (provided) => ({
											...provided,
											color: 'black',
										}),
										singleValue: (provided) => ({
											...provided,
											color: 'black',
										}),
									}}
									className="form-control form-control-lg"
									placeholder="Select a trigger"
								/>
								{/* Render the additional select based on the trigger option */}
								{selectedNode.name === 'when-user-subscribes' && (
									<Select
										onChange={handleAdditionalChange}
										value={groups.filter((group) => selectedNode.data?.group?.includes(group.value))}
										options={groups}
										styles={{
											option: (provided) => ({
												...provided,
												color: 'black',
											}),
											control: (provided) => ({
												...provided,
												color: 'black',
											}),
											singleValue: (provided) => ({
												...provided,
												color: 'black',
											}),
										}}
										className="form-control form-control-lg"
										placeholder="Select a group"
									/>
								)}
								{selectedNode.name === 'when-user-opens-campaign' && (
									<Select
										onChange={handleAdditionalChange}
										value={avlCampaigns.filter((cmp) => selectedNode.data?.cmp?.includes(cmp.value))}
										options={avlCampaigns}
										styles={{
											option: (provided) => ({
												...provided,
												color: 'black',
											}),
											control: (provided) => ({
												...provided,
												color: 'black',
											}),
											singleValue: (provided) => ({
												...provided,
												color: 'black',
											}),
										}}
										className="form-control form-control-lg"
										placeholder="Select a campaign" // Default placeholder for additional select
									/>
								)}

								{selectedNode.name === 'when-user-clicks-link' && (
									<Select
										onChange={handleAdditionalChange}
										value={cmpLinks.filter((cmp) => selectedNode.data?.link?.includes(cmp.value))}
										options={cmpLinks}
										isSearchable
										styles={{
											option: (provided) => ({
												...provided,
												color: 'black',
											}),
											control: (provided) => ({
												...provided,
												color: 'black',
											}),
											singleValue: (provided) => ({
												...provided,
												color: 'black',
											}),
										}}
										className="form-control form-control-lg"
										placeholder="Select a link" // Default placeholder for additional select
									/>
								)}
							</div>
						)}

						{selectedNode && selectedNode.type === 'email' && (
							<div style={{ flexGrow: '1' }}>
								<Select
									onChange={handleTriggerSelectChange}
									value={
										selectedNode.name
											? {
													value: selectedNode.name,
													label: templateOptions.find((option) => {
														return option.value === selectedNode.name
													}).label,
											  }
											: null // Handle the case where `name` is null or undefined
									}
									options={templateOptions}
									styles={{
										option: (provided) => ({
											...provided,
											color: 'black',
										}),
										control: (provided) => ({
											...provided,
											color: 'black',
										}),
										singleValue: (provided) => ({
											...provided,
											color: 'black',
										}),
									}}
									className="form-control form-control-lg"
									placeholder="Select a Template" // Default placeholder when no name is present
								/>

								<div className="mt-2 w-100" style={{ padding: '12px' }}>
									<label>Email Subject</label>
									<br />
									<input
										className="w-100"
										type="text"
										onChange={handleEmailSubjectData}
										name="emailSubject"
										style={{ padding: '5px 10px', borderRadius: '5px' }}
										value={selectedNode ? selectedNode?.data?.emailSubject : null}
									/>
								</div>
							</div>
						)}

						{selectedNode && selectedNode.type === 'action' && (
							<div style={{ flexGrow: '1' }}>
								<Select
									onChange={handleTriggerSelectChange}
									value={
										selectedNode.name
											? {
													value: selectedNode.name,
													label: actionOptions.find((option) => {
														return option.value === selectedNode.name
													}).label,
											  }
											: null // Handle the case where `name` is null or undefined
									}
									options={actionOptions}
									styles={{
										option: (provided) => ({
											...provided,
											color: 'black',
										}),
										control: (provided) => ({
											...provided,
											color: 'black',
										}),
										singleValue: (provided) => ({
											...provided,
											color: 'black',
										}),
									}}
									className="form-control form-control-lg"
									placeholder="Select an action" // Default placeholder when no name is present
								/>

								{(selectedNode.name === 'move-to-group' || selectedNode.name === 'copy-to-group' || selectedNode.name === 'remove-from-group') && (
									<Select
										onChange={handleAdditionalChange}
										value={groups.filter((group) => selectedNode.data?.group?.includes(group.value))}
										options={groups}
										styles={{
											option: (provided) => ({
												...provided,
												color: 'black',
											}),
											control: (provided) => ({
												...provided,
												color: 'black',
											}),
											singleValue: (provided) => ({
												...provided,
												color: 'black',
											}),
										}}
										className="form-control form-control-lg"
										placeholder="Select a group" // Default placeholder for additional select
									/>
								)}

								{/* Apply button to save the settings */}
								{/* <div className="form-control form-control-lg ">
									<button className="btn btn-primary mt-3" onClick={handleApply}>
										Save Changes
									</button>
								</div> */}
							</div>
						)}

						{selectedNode && selectedNode.type === 'condition' && (
							<div style={{ flexGrow: '1' }}>
								<Select
									onChange={handleTriggerSelectChange}
									value={
										selectedNode.name
											? {
													value: selectedNode.name,
													label: conditionOptions2.find((option) => {
														return option.value === selectedNode.name
													})?.label,
											  }
											: null // Handle the case where `name` is null or undefined
									}
									options={conditionOptions2}
									styles={{
										option: (provided) => ({
											...provided,
											color: 'black',
										}),
										control: (provided) => ({
											...provided,
											color: 'black',
										}),
										singleValue: (provided) => ({
											...provided,
											color: 'black',
										}),
									}}
									className="form-control form-control-lg"
									placeholder="Select a condition"
								/>
								{selectedNode.name === 'workflow-activity' && (
									<>
										<Select
											options={workflowCampaigns}
											value={workflowCampaigns.filter((cmp) => selectedNode.data?.email_node_id === cmp.value)}
											onChange={handleAdditionalChange}
											styles={{
												option: (provided) => ({
													...provided,
													color: 'black',
												}),
												control: (provided) => ({
													...provided,
													color: 'black',
												}),
												singleValue: (provided) => ({
													...provided,
													color: 'black',
												}),
											}}
											className="form-control form-control-lg"
											placeholder="Select Workflow Email"
										/>

										{selectedNode.name === 'workflow-activity' && selectedNode?.data?.email_node_id && (
											<>
												<Select
													options={workflowConditionOptions}
													value={workflowConditionOptions.filter((option) => selectedNode.data?.trigger === option.value)}
													onChange={handleWorkflowCondition}
													styles={{
														option: (provided) => ({
															...provided,
															color: 'black',
														}),
														control: (provided) => ({
															...provided,
															color: 'black',
														}),
														singleValue: (provided) => ({
															...provided,
															color: 'black',
														}),
													}}
													className="form-control form-control-lg"
													placeholder="Select a trigger"
												/>
											</>
										)}

										{selectedNode.name === 'workflow-activity' &&
											selectedNode?.data?.email_node_id &&
											(selectedNode?.data?.trigger === 'cmp_link_clicked' || selectedNode?.data?.trigger === 'cmp_link_not_clicked') && (
												<>
													<Select
														options={getTplIdLinks(selectedNode?.data?.email_node_id)}
														// value={workflowConditionOptions.filter((option) => electedNode.data?.trigger === option.value)}
														value={getTplIdLinks(selectedNode?.data?.email_node_id).filter((option) => selectedNode.data?.link === option.value)}
														onChange={handleWorkflowEmailLink}
														styles={{
															option: (provided) => ({
																...provided,
																color: 'black',
															}),
															control: (provided) => ({
																...provided,
																color: 'black',
															}),
															singleValue: (provided) => ({
																...provided,
																color: 'black',
															}),
														}}
														className="form-control form-control-lg"
														placeholder="Select a link"
													/>
												</>
											)}
									</>
								)}
								{selectedNode.name === 'cmp-activity' && (
									<>
										<Select
											onChange={handleAdditionalChange}
											value={avlCampaigns.filter((cmp) => selectedNode.data?.cmp === cmp.value)}
											options={avlCampaigns}
											styles={{
												option: (provided) => ({
													...provided,
													color: 'black',
												}),
												control: (provided) => ({
													...provided,
													color: 'black',
												}),
												singleValue: (provided) => ({
													...provided,
													color: 'black',
												}),
											}}
											className="form-control form-control-lg"
											placeholder="Select a campaign" // Default placeholder for additional select
										/>

										{selectedNode.name === 'cmp-activity' && selectedNode?.data?.cmp && (
											<>
												<Select
													options={workflowConditionOptions}
													value={workflowConditionOptions.filter((option) => selectedNode.data?.trigger === option.value)}
													onChange={handleWorkflowCondition}
													styles={{
														option: (provided) => ({
															...provided,
															color: 'black',
														}),
														control: (provided) => ({
															...provided,
															color: 'black',
														}),
														singleValue: (provided) => ({
															...provided,
															color: 'black',
														}),
													}}
													className="form-control form-control-lg"
													placeholder="Select a trigger"
												/>
											</>
										)}

										{selectedNode.name === 'cmp-activity' &&
											selectedNode?.data?.cmp &&
											(selectedNode?.data?.trigger === 'cmp_link_clicked' || selectedNode?.data?.trigger === 'cmp_link_not_clicked') && (
												<>
													<Select
														onChange={handleCmpEmailLink}
														value={cmpLinks.filter((cmp) => selectedNode.data?.link?.includes(cmp.value))}
														options={cmpLinks}
														styles={{
															option: (provided) => ({
																...provided,
																color: 'black',
															}),
															control: (provided) => ({
																...provided,
																color: 'black',
															}),
															singleValue: (provided) => ({
																...provided,
																color: 'black',
															}),
														}}
														isSearchable
														className="form-control form-control-lg"
														placeholder="Select a link" // Default placeholder for additional select
													/>
												</>
											)}
									</>
								)}

								{selectedNode.name === 'when-user-opens-campaign' && (
									<Select
										onChange={handleAdditionalChange}
										value={avlCampaigns.filter((cmp) => selectedNode.data?.cmp?.includes(cmp.value))}
										options={avlCampaigns}
										styles={{
											option: (provided) => ({
												...provided,
												color: 'black',
											}),
											control: (provided) => ({
												...provided,
												color: 'black',
											}),
											singleValue: (provided) => ({
												...provided,
												color: 'black',
											}),
										}}
										className="form-control form-control-lg"
										placeholder="Select a trigger" // Default placeholder for additional select
									/>
								)}
								{selectedNode.name === 'when-user-clicks-link' && (
									<Select
										onChange={handleAdditionalChange}
										value={cmpLinks.filter((cmp) => selectedNode.data?.link?.includes(cmp.value))}
										options={cmpLinks}
										styles={{
											option: (provided) => ({
												...provided,
												color: 'black',
											}),
											control: (provided) => ({
												...provided,
												color: 'black',
											}),
											singleValue: (provided) => ({
												...provided,
												color: 'black',
											}),
										}}
										isSearchable
										className="form-control form-control-lg"
										placeholder="Select a link" // Default placeholder for additional select
									/>
								)}
								{/* Apply button to save the settings */}
								{/* <div className="form-control form-control-lg ">
									<button className="btn btn-primary mt-3" onClick={handleApply}>
										Save Changes
									</button>
								</div> */}
							</div>
						)}

						{selectedNode && selectedNode.type === 'delay' && (
							<div style={{ flexGrow: '1' }}>
								<Select
									options={[
										{
											label: 'Days',
											value: 'days',
										},
										{
											label: 'Hours',
											value: 'hours',
										},
									]}
									value={[
										{
											label: 'Days',
											value: 'days',
										},
										{
											label: 'Hours',
											value: 'hours',
										},
									].filter((cmp) => selectedNode.data?.delay?.includes(cmp.value))}
									onChange={handleAdditionalChange}
									placeholder="Select delay type" // Default placeholder when no name is present
									styles={{
										option: (provided) => ({
											...provided,
											color: 'black',
										}),
										control: (provided) => ({
											...provided,
											color: 'black',
										}),
										singleValue: (provided) => ({
											...provided,
											color: 'black',
										}),
									}}
									className="form-control form-control-lg"
								/>
								<div style={{ padding: '12px' }}>
									<label>Delay:</label>
									<br />
									<input
										value={selectedNode ? selectedNode?.data?.delayValue : null}
										name="delayNumber"
										type="number"
										className="w-100"
										onChange={handleDelayData}
										style={{ padding: '5px 10px', borderRadius: '5px' }}
									/>
								</div>
							</div>
						)}

						<div className="p-4 dragflow-save-action-container" style={{ flexShrink: '0' }}>
							<div className="flow-active-toggle" draggable={false}>
								<Form.Group>
									<Form.Check
										type="switch"
										label="Automation status"
										id="disabled-custom-switch"
										checked={data?.active || false}
										onChange={(evt) => {
											setData({ ...data, active: evt.target.checked })
										}}
									/>
								</Form.Group>
							</div>

							<div className="d-flex" style={{ gap: '10px' }}>
								{selectedNode && (
									<div
										className="flow-save-btn btn  btn-success"
										draggable={false}
										onClick={() => {
											//exportData()
											handleApply()
										}}
									>
										Save Node
									</div>
								)}
								<div
									className="flow-save-btn btn btn-success"
									draggable={false}
									onClick={() => {
										exportData()
										// handleApply()
									}}
								>
									Save Flow
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className="dragflow-conditions-actions-container" style={{ flexGrow: 1, overflowY: 'auto' }}>
							<Tabs defaultActiveKey="conditions" id="uncontrolled-tab-example" activeKey={tab} onSelect={(k) => goToTab(k)} className="mb-3">
								<Tab eventKey="actions" title="Actions">
									{/* Action nodes here */}

									{/* Display settings when a node is selected */}
								</Tab>
								<Tab eventKey="conditions" title="Conditions">
									{/* Condition nodes here */}
								</Tab>
							</Tabs>
						</div>
						<div className="p-4 dragflow-save-action-container" style={{ flexShrink: '0' }}>
							<div className="flow-active-toggle" draggable={false}>
								<Form.Group>
									<Form.Check
										type="switch"
										label="Automation status"
										id="disabled-custom-switch"
										checked={data?.active || false}
										onChange={(evt) => {
											setData({ ...data, active: evt.target.checked })
										}}
									/>
								</Form.Group>
							</div>
							<div
								className="flow-save-btn btn btn-lg btn-success"
								draggable={false}
								onClick={() => {
									exportData()
								}}
							>
								Save Flow
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default FlowEditor
