import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './app/App'
import './i18n'
import * as serviceWorker from './serviceWorker'
import { LoadingProvider } from './app/ToggleContext'

// ReactDOM.render(
//   <BrowserRouter>
//     <LoadingProvider>
//       <App />
//   </LoadingProvider>,
//   </BrowserRouter>
// , document.getElementById('root'));
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	// <React.StrictMode>
	<BrowserRouter>
		<LoadingProvider>
			<App />
		</LoadingProvider>
	</BrowserRouter>
	// </React.StrictMode>
)

serviceWorker.unregister()
