import React, { Component, useEffect, useState, useCallback } from 'react'
import Dropzone from 'react-dropzone'

import User from '../services/User'
import { ApiService } from '../services/ApiService'
import { useParams } from 'react-router-dom'
import LoadingContext from '../ToggleContext'
import 'flatpickr/dist/themes/dark.css'
import Flatpickr from 'react-flatpickr'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tab, Tabs, Form, Pagination } from 'react-bootstrap'
import Select from 'react-select'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Papa from 'papaparse'
const FileDownload = require('js-file-download')
const mSwal = withReactContent(Swal)

function withParams(Component) {
	return (props) => <Component {...props} params={useParams()} />
}

export class AllSubscribers extends Component {
	static contextType = LoadingContext
	constructor(props) {
		super(props)
		this.state = {
			tab: 'subs',
			loading: true,
			itemsPerPage: 20,
			currentPage: 1,
			user: {},
			groupId: '',
			skeletons: [],
			subscribers: { data: [], meta: [] },
			availGroups: [],
			searchEml: '',
			searchName: '',
			searchGroup: '',
			searchDateFrom: '',
			searchDateTo: '',
		}

		this.applySubsFilters = this.applySubsFilters.bind(this)
	}

	async getAllGroups() {
		let user = User.get()
		let resp = await ApiService.get('fairymailer/getGroups?polulate=*', user.jwt)
		if (resp.data && resp.data.data) {
			console.log('avail groups', resp.data)
			this.setState({
				availGroups: resp.data.data.map((g) => {
					return { value: g.id, label: g.name }
				}),
				meta: resp.data.meta,
			})
		}
	}
	async componentDidMount() {
		const groupId = this.props.params.groupId
		this.setState({ user: await User.get() })
		this.setState({ groupId: groupId })
		this.getAllGroups()
		if (groupId && groupId.length > 0) {
			this.fetchSubscribers(this.state.currentPage)
		}
	}

	async fetchSubscribers(page = 1) {
		this.context.setLoading_(true)
		const groupId = this.props.params.groupId
		let user = await User.get()
		let tempdata
		console.log('state in fetchSubscribers', this.state)
		if ('all' === groupId) {
			tempdata = await ApiService.get(
				`fairymailer/getSubscribers/?sort[0]=createdAt:desc&pagination[pageSize]=${this.state.itemsPerPage}&pagination[page]=${page}&populate[groups][count]=1`,
				user.jwt
			)
			if (tempdata && tempdata.data) {
				console.log('subscribers', tempdata.data)
				this.setState({ subscribers: tempdata.data, currentPage: tempdata.data.meta.pagination.page })
			}
			this.context.setLoading_(false)
			return
		}
		// tempdata = await ApiService.get(`groups?filters[udid]=${groupId}&populate=subscribers`,this.state.user.jwt);
		tempdata = await ApiService.get(`fairymailer/getSubscribersOfGroup/${groupId}?pagination[page]=${page}&pagination[pageSize]=${this.state.itemsPerPage}&populate[groups][count]=1`, User.get().jwt)
		if (tempdata) {
			console.log('group subs: ', tempdata.data)
			this.setState({ subscribers: tempdata.data })
		} else {
			mSwal.fire({ icon: 'error', text: 'Failed to load subscribers data.' })
		}
		this.context.setLoading_(false)
	}

	async editSubscriber(Subscriber_id) {
		window.location.href = `/subscriber/${Subscriber_id}`
	}

	async applySubsFilters() {
		const qs = require('qs')

		this.context.setLoading_(true)
		let user = User.get()
		let filters = []

		if (this.state.searchEml.length > 0) {
			filters.push({
				email: {
					$contains: this.state.searchEml,
				},
			})

			// filters.push(`filters[email][$contains]=${this.state.searchEml}`)
		}
		if (this.state.searchName.length > 0) {
			filters.push({
				name: {
					$contains: this.state.searchName,
				},
			})
			// filters.push(`filters[name][$contains]=${this.state.searchName}`)
		}
		if (this.state.searchDateFrom) {
			filters.push({
				createdAt: {
					$gte: new Date(this.state.searchDateFrom).toISOString(),
				},
			})
			// filters.push(`filters[createdAt][$gte]=${new Date(this.state.searchDateFrom).toISOString()}`)
		}
		if (this.state.searchDateTo) {
			filters.push({
				createdAt: {
					$lte: new Date(this.state.searchDateTo).toISOString(),
				},
			})
			// filters.push(`filters[createdAt][$lte]=${new Date(this.state.searchDateTo).toISOString()}`)
		}
		if (this.state.searchGroup.length > 0) {
			filters.push({
				groups: this.state.searchGroup.map((grp) => grp.value).join(','),
			})
			// filters.push(`filters[groups]=${this.state.searchGroup.map((grp) => grp.value).join(',')}`)
		}

		const finalFilters = {
			filters: {
				$and: [...filters],
			},
		}
		const query = qs.stringify(finalFilters, { encodeValuesOnly: true })
		console.log('filters are : ', finalFilters)
		console.log('searchGroup is : ', this.state.searchGroup)

		// let resp = await ApiService.get(`fairymailer/getSubscribers?${filters.join('&')}&pagination[page]=${1}&populate[groups][count]=1`, user.jwt) ///?page=${page}&pageSize=${pageSize}
		let resp = await ApiService.get(`fairymailer/getSubscribers?${query}&pagination[page]=${1}&pagination[pageSize]=${this.state.itemsPerPage}&populate[groups][count]=1`, user.jwt) ///?page=${page}&pageSize=${pageSize}
		console.log('Subscribers', resp)
		// return
		if (resp.data && resp.data.data) {
			this.setState({ subscribers: resp.data, tab: 'subs' })
			this.context.setLoading_(false)
			this.goToTab('subs')
		}
	}
	async exportSubs() {
		this.context.setLoading_(true)
		let user = User.get()
		let filters = []
		if (this.state.searchEml.length > 0) {
			filters.push(`filters[email][$contains]=${this.state.searchEml}`)
		}
		if (this.state.searchName.length > 0) {
			filters.push(`filters[name][$contains]=${this.state.searchName}`)
		}
		if (this.state.searchDateFrom) {
			filters.push(`filters[createdAt][$gte]=${new Date(this.state.searchDateFrom).toISOString()}`)
		}
		if (this.state.searchDateTo) {
			filters.push(`filters[createdAt][$lte]=${new Date(this.state.searchDateTo).toISOString()}`)
		}
		if (this.state.searchGroup.length > 0) {
			filters.push(`filters[groups]=${this.state.searchGroup.map((grp) => grp.value).join(',')}`)
		}
		let resp = await ApiService.get(`fairymailer/export-subs/?${filters.join('&')}&pagination[pageSize]=${100}&populate[groups][count]=1`, user.jwt) ///?page=${page}&pageSize=${pageSize}
		this.context.setLoading_(false)
		console.log('Subscribers export', resp)
		FileDownload(resp.data, `subscribers_${new Date().getTime()}.csv`)
	}
	async handleCsvUpload(file) {
		this.context.setLoading_(true)
		console.log(file[0])
		Papa.parse(file[0], {
			header: true,
			complete: (results) => {
				this.context.setLoading_(false)
				if (results && results.data && results.data.length > 0) {
					mSwal
						.fire({
							customClass: 'importsubs-map-picker',
							html: (
								<div>
									<h4>Please select the proper field association</h4>
									<div className="mapping-modal-field">
										<label>Email field from CSV:</label>
										<select id="emailfield">
											{Object.keys(results.data[0]).map((k) => {
												return <option value={k}>{k}</option>
											})}
										</select>
									</div>
									<br></br>
									<div className="mapping-modal-field">
										<label>Name field from CSV:</label>
										<select id="namefield">
											{Object.keys(results.data[0]).map((k) => {
												return <option value={k}>{k}</option>
											})}
										</select>
									</div>
									<br></br>
									<div className="mapping-modal-field">
										<label>Destination group</label>
										<select id="importmappinggroupid">
											{this.state.availGroups.map((k) => {
												return <option value={k.value}>{k.label}</option>
											})}
										</select>
									</div>
								</div>
							),
							confirmButtonText: 'Import',
							showCancelButton: true,
							cancelButtonText: 'Cancel',
							preConfirm: () => {
								return {
									email: document.querySelector('#emailfield').value,
									name: document.querySelector('#namefield').value,
									group: document.querySelector('#importmappinggroupid').value,
								}
							},
						})
						.then(async (result) => {
							this.context.setLoading_(true)
							console.log(result)
							const formData = new FormData()
							formData.append('file', file[0])
							formData.append('meta', JSON.stringify(result.value))
							let resp = await ApiService.post('fairymailer/upload-csv', formData, User.get().jwt, {
								'Content-Type': 'multipart/form-data',
								Authorization: 'Bearer ' + User.get().jwt,
							})
							console.log(resp)
							this.context.setLoading_(false)
							if (resp && resp.data && resp.data.code == 200) {
								mSwal.fire({ icon: 'success', timer: 1000 }).then(() => {
									window.location.reload()
								})
							} else {
								mSwal.fire({ icon: 'error', text: 'Failed to import subs. Please contact our support team.' })
							}
						})
				} else {
					mSwal.fire({
						icon: 'error',
						text: 'An error occured. Are you sure this is a valid CSV file?',
					})
				}
			},
			error: (error) => {
				mSwal.fire({
					icon: 'error',
					text: 'An error occured. Are you sure this is a valid CSV file?',
				})
				this.context.setLoading_(false)
			},
		})
	}
	async importSubs() {
		mSwal.fire({
			html: (
				<Dropzone onDrop={(acceptedFiles) => this.handleCsvUpload(acceptedFiles)} style={{ border: '2px dashed black' }}>
					{({ getRootProps, getInputProps }) => (
						<section>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<p>Drag 'n' drop CSV files here, or click to select them.</p>
							</div>
						</section>
					)}
				</Dropzone>
			),
		})
	}
	preventSubmit() {
		return false
	}
	cleanDate(date) {
		date = date.split('T')
		return `${date[0]} ${date[1].split(':')[0] + ':' + date[1].split(':')[1]}`
	}
	goToTab(tab) {
		this.setState({ tab: tab })
	}
	updateInputValue(input, evt) {
		let st = {}
		if (input == 'searchGroup') {
			st[input] = evt
		} else {
			st[input] = evt.target.value
		}
		this.setState(st)
	}
	render() {
		const { tab, groupId, skeletons, subscribers, availGroups, meta } = this.state
		return (
			<div className="subscribers-container">
				<div className="row">
					<div className="col-sm-12 grid-margin stretch-card">
						<div className="card">
							<div className="card-body">
								<div className="card-title" style={{ justifyContent: 'end' }}>
									<button
										type="button"
										className="btn btn-success"
										onClick={() => {
											this.exportSubs()
										}}
									>
										<i className="mdi mdi-file-export"></i> Export CSV
									</button>
									&nbsp;
									<button
										type="button"
										className="btn btn-success"
										onClick={() => {
											this.importSubs()
										}}
									>
										<i className="mdi mdi-file-import"></i> Import CSV
									</button>
								</div>
								{/* <div className="card-title">
                  <button type="button" className="btn btn-success"  onClick={ ()=>{window.location.href='/subscribers/new'} }>
                      <i className="mdi mdi-message-plus"></i> Import Subscribers
                  </button>
                </div> */}
								<Tabs defaultActiveKey="subs" id="uncontrolled-tab-example" activeKey={tab} onSelect={(k) => this.goToTab(k)} className="mb-3">
									<Tab eventKey="subs" title={'All Subscribers' + (this.state.groupName && this.state.groupName.length > 0 ? ' of "' + this.state.groupName + '"' : '')}>
										<div className="table-responsive tab-content">
											<table className="table table-bordered table-striped">
												<thead>
													<tr>
														<th>Name</th>
														<th>E-mail</th>
														<th>Subscribed</th>
														<th>Groups</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{subscribers && subscribers.data.length > 0
														? subscribers.data.map((sub, index) => {
																return (
																	<tr key={`sub-${index}`}>
																		<td>{sub.name}</td>
																		<td>{sub.email}</td>
																		<td> {this.cleanDate(sub.createdAt)} </td>
																		<td>{sub.groups.count}</td>
																		<td>
																			<button
																				type="button"
																				className="btn btn-warning"
																				onClick={() => {
																					this.editSubscriber(sub.udid)
																				}}
																			>
																				<i className="mdi mdi-grease-pencil"></i>Edit
																			</button>{' '}
																			&nbsp;
																		</td>
																	</tr>
																)
														  })
														: skeletons.map((cmp, index) => {
																return (
																	<tr>
																		<td>
																			<Skeleton />{' '}
																		</td>
																		<td>
																			<Skeleton />{' '}
																		</td>
																		<td>
																			<Skeleton />{' '}
																		</td>
																		<td>
																			<Skeleton />{' '}
																		</td>
																		<td>
																			<Skeleton />{' '}
																		</td>
																	</tr>
																)
														  })}
												</tbody>
											</table>
										</div>
										<br></br>
										<br></br>
										{/* <Pagination>
											{Array.from({ length: this.state.subscribers.meta.pagination?.pageCount }, (_, i) => i + 1).map((num) => {
												return (
													<Pagination.Item
														key={num}
														active={num === this.state.subscribers.meta.pagination?.page}
														onClick={() => {
															this.fetchSubscribers(num)
														}}
													>
														{num}
													</Pagination.Item>
												)
											})}
										</Pagination> */}
										<Pagination>
											{(() => {
												const { pageCount, page } = this.state.subscribers.meta.pagination || { pageCount: 0, page: 1 }
												const pageRangeToShow = 10
												const pages = []

												// Ensure the pagination has more than 1 page
												if (pageCount > 1) {
													// Always display the first page
													pages.push(
														<Pagination.Item key={1} active={page === 1} onClick={() => this.fetchSubscribers(page)}>
															1
														</Pagination.Item>
													)

													// Add ellipses if there are more than 10 pages and the current page is not near the beginning
													if (page > pageRangeToShow / 2 + 1) {
														pages.push(<Pagination.Ellipsis key="start-ellipsis" />)
													}

													// Calculate the start and end of the range around the current page
													const startPage = Math.max(2, page - Math.floor(pageRangeToShow / 2))
													const endPage = Math.min(pageCount - 1, page + Math.floor(pageRangeToShow / 2))

													// Display pages around the current page
													for (let i = startPage; i <= endPage; i++) {
														pages.push(
															<Pagination.Item key={i} active={i === page} onClick={() => this.fetchSubscribers(i)}>
																{i}
															</Pagination.Item>
														)
													}

													// Add ellipses if the current page is not near the end
													if (page < pageCount - pageRangeToShow / 2) {
														pages.push(<Pagination.Ellipsis key="end-ellipsis" />)
													}

													// Always display the last page
													pages.push(
														<Pagination.Item key={pageCount} active={page === pageCount} onClick={() => this.fetchSubscribers(pageCount)}>
															{pageCount}
														</Pagination.Item>
													)
												}

												return pages
											})()}
										</Pagination>
									</Tab>
									{this.state.groupName && this.state.groupName.length > 0 ? (
										''
									) : (
										<Tab eventKey="filters" title="Filter Subscribers">
											<Form className="row" onSubmit={this.preventSubmit}>
												<Form.Group className="mb-3 col-xs-12 col-md-4" controlId="filterSubs.email">
													<Form.Label>Email contains</Form.Label>
													<Form.Control type="text" placeholder="name@example.com" value={this.state.searchEml} onChange={(evt) => this.updateInputValue('searchEml', evt)} />
												</Form.Group>

												<Form.Group className="mb-3 col-xs-12 col-md-4" controlId="filterSubs.name">
													<Form.Label>Name contains</Form.Label>
													<Form.Control type="text" placeholder="John Doe" value={this.state.searchName} onChange={(evt) => this.updateInputValue('searchName', evt)} />
												</Form.Group>

												<Form.Group className="mb-3 col-xs-12 col-md-4 select2box" controlId="filterSubs.group">
													<Form.Label>Belongs to group</Form.Label>
													<Select
														options={this.state.availGroups}
														theme={(theme) => ({
															...theme,
															colors: {
																...theme.colors,
																text: 'orangered',
																primary25: '#0090e7',
																primary: 'black',
															},
														})}
														isMulti
														className="form-control form-control-lg"
														id="campaignGroup"
														value={this.state.searchGroup}
														onChange={(evt) => this.updateInputValue('searchGroup', evt)}
													/>
												</Form.Group>

												<Form.Group className="mb-3 col-xs-12 col-md-4" controlId="filterSubs.dateFrom">
													<Form.Label>Subscribed after:</Form.Label>
													<Flatpickr
														className="form-control form-control-lg"
														data-enable-time
														options={{}}
														value={this.state.searchDateFrom}
														onChange={([date]) => {
															this.setState({ searchDateFrom: date })
															console.log(date)
														}}
													/>
												</Form.Group>

												<Form.Group className="mb-3 col-xs-12 col-md-4" controlId="filterSubs.dateTo">
													<Form.Label>Subscribed before:</Form.Label>
													{/* <Form.Control type="date" value={this.state.searchDateTo} onChange={evt => this.updateInputValue('searchDateTo',evt)}/> */}
													{/* <Form.Control className="flatpickr" type="date" value={this.state.searchDateTo} onChange={evt => this.updateInputValue('searchDateTo',evt)}/> */}
													<Flatpickr
														className="form-control form-control-lg"
														data-enable-time
														options={{}}
														value={this.state.searchDateTo}
														onChange={([date]) => {
															this.setState({ searchDateTo: date })
															console.log(date)
														}}
													/>
												</Form.Group>

												<Form.Group className="mt-3 col-xs-12 col-md-4" controlId="filterSubs.name">
													<button className="btn btn-lg btn-success justify" type="button" onClick={this.applySubsFilters}>
														{' '}
														Apply filters
													</button>
												</Form.Group>
											</Form>
										</Tab>
									)}
								</Tabs>

								{/* <div class="pagination">
                  {meta.pagination && meta.pagination.pageCount>1 ? 
                  [...Array(meta.pagination.pageCount+1).keys()].splice(1).forEach(pageNum=>{
                    return <div class="pagenum" onClick={()=>{this.getSubscribers(pageNum)}}> {pageNum} </div>
                  })
                  : ''}
                </div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withParams(AllSubscribers)
